import { Departure } from "@zvv-fkm/types";
import { DepartureNotification } from "@zvv-fkm/types/api";
import checkIsTicketMachine from "../../../../lib/hooks/CheckIsTicketMachine";
import { BaseRow } from "../BaseRow";
import LineCell from "../rowElements/LineCell";
import TimeCell from "../rowElements/TimeCell";

export function IntervalDepartureRow({
  departure,
  notifications,
  multipleDirection,
  showFootPath,
  showPlatform,
  debugMode,
}: {
  departure: Departure;
  notifications: DepartureNotification[];
  multipleDirection: boolean;
  showFootPath: boolean;
  showPlatform: boolean;
  debugMode: boolean;
}) {
  return (
    <BaseRow>
      <LineCell
        departure={departure}
        interval={true}
        notifications={notifications}
        type={departure.transportTypeIcon}
        lineName={departure.lineName}
        lineBgColor={departure.color}
        lineFgColor={departure.textColor}
        wheelchairAccessible={departure.wheelchairSymbol}
        affectedByNotification={departure.affectedByNotifications}
      />
      <div className="flex">
        <span className="truncate">{departure.destinationStationName}</span>
      </div>
      {multipleDirection && (
        <div className="flex">
          <span className="">{departure.departureStationName}</span>
        </div>
      )}
      {showFootPath && multipleDirection && (
        <div className=" flex justify-center">
          <span>{departure.walkingDistance}′</span>
        </div>
      )}
      {showPlatform && (
        <div className="flex justify-center">
          <span>{departure.platform}</span>
        </div>
      )}
      <TimeCell
        departure={departure}
        intervalDepartureRow={true}
        type={departure.transportTypeIcon}
        debugMode={debugMode}
      />
      {departure.messageText && (
        <div
          className={`col-span-full flex !pl-12 text-yellow  ${checkIsTicketMachine() ? "text-rowTM" : "text-row"}`}
        >
          {departure.messageText}
        </div>
      )}
    </BaseRow>
  );
}
