import { Departure } from "@zvv-fkm/types";
import { ArrivalStatus } from "@zvv-fkm/types/departure";
import { differenceInSeconds, format } from "date-fns";
import { toZonedTime } from "date-fns-tz/toZonedTime";
import { useTranslation } from "react-i18next";
import { TransportTypeVehicleIcon } from "./TransportVehicleIcon";

export default function TimeCell({
  departure,
  intervalDepartureRow,
  type,
  debugMode,
}: {
  departure: Departure;
  intervalDepartureRow: boolean;
  type: number;
  debugMode: boolean;
}) {
  const { t } = useTranslation();
  const targetDate = departure.time + departure.delayExact;
  const currentDate = new Date();
  const minutesDifference = Math.ceil(
    differenceInSeconds(targetDate, currentDate) / 60,
  );

  function isWithin30Minutes() {
    const endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    return new Date(targetDate) <= endTime && !debugMode;
  }

  function isDeparting() {
    return (
      (minutesDifference == 0 || new Date(targetDate) <= currentDate) &&
      !debugMode
    );
  }

  function getTimeString() {
    const date = new Date(departure.time);
    const zurichTime = toZonedTime(date, "Europe/Zurich");
    if (isWithin30Minutes() && intervalDepartureRow) {
      return `${t("in_minutes_text")} ${minutesDifference}′`;
    } else {
      return format(zurichTime, "HH:mm");
    }
  }

  function showMovingIcon() {
    return (
      ((isDeparting() && departure.arrivalStatus === ArrivalStatus.UNKNOWN) ||
        departure.arrivalStatus === ArrivalStatus.AT_STATION) &&
      !debugMode
    );
  }

  function showIcon() {
    return (
      departure.arrivalStatus === ArrivalStatus.TRAVELLING && isDeparting()
    );
  }

  function showCancelled() {
    return departure.cancelled;
  }

  function toMinutes(milliseconds: number) {
    return Math.floor(milliseconds / 1000 / 60);
  }

  if (showCancelled()) {
    return (
      <div className="] flex items-center text-yellow">
        <span>{t("cancelled")}</span>
      </div>
    );
  }

  if (showMovingIcon() || showIcon()) {
    return (
      <div className={`flex ${intervalDepartureRow && "justify-center"}`}>
        <TransportTypeVehicleIcon
          transportType={type}
          className={`shrink-0 pt-[calc(0.5rem*var(--row-scaling))] text-[calc(4.2rem*var(--row-scaling))] text-white ${showMovingIcon() && "animate-pulse duration-100 "}`}
        />
      </div>
    );
  }

  return (
    <div className={`flex  ${intervalDepartureRow && "justify-center"}`}>
      <span>
        {getTimeString()}
        {((departure.delay !== 0 && !intervalDepartureRow) || debugMode) && (
          <span className="ml-2 text-yellow ">
            + {toMinutes(departure.delay)}′
          </span>
        )}
      </span>
    </div>
  );
}
