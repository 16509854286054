import { DepartureNotification, NotificationType } from "@zvv-fkm/types/api";
import { AccessibilityLevel, Departure } from "@zvv-fkm/types/departure";
import checkIsTicketMachine from "../../../../lib/hooks/CheckIsTicketMachine";
import { useNotificationContext } from "../../../../lib/hooks/Notifications";
import { getScreenReaderText } from "../../getScreenReaderText";
import { AccessibilityLevelIcon } from "./AccessibilityLevelIcon";
import { AlertIcon } from "./AlertIcon";
import { LineBadge } from "./LineBadge";
import { TransportTypeVehicleIcon } from "./TransportVehicleIcon";

export default function LineCell({
  interval,
  departure,
  lineFgColor,
  lineBgColor,
  lineName,
  type,
  wheelchairAccessible,
  affectedByNotification,
  notifications,
}: {
  interval: boolean;
  departure: Departure;
  lineFgColor: string;
  lineBgColor: string;
  lineName: string;
  type: number;
  wheelchairAccessible: AccessibilityLevel;
  affectedByNotification: string[];
  notifications: DepartureNotification[];
}) {
  const { activeNotification } = useNotificationContext();
  const isAffected = notifications.some((n) =>
    affectedByNotification.includes(n.notificationId),
  );
  function getType(): NotificationType | undefined {
    if (affectedByNotification.length <= 1) {
      return getNotificationType(affectedByNotification[0]);
    }

    if (
      activeNotification !== undefined &&
      affectedByNotification.includes(activeNotification?.id)
    ) {
      return activeNotification?.type;
    }

    const types = affectedByNotification.map(getNotificationType);
    return getHighestPriorityType(types);
  }

  function getNotificationType(
    notificationId: string | undefined,
  ): NotificationType | undefined {
    return notifications.find((n) => n.notificationId === notificationId)?.type;
  }

  function getHighestPriorityType(
    types: (NotificationType | undefined)[],
  ): NotificationType {
    if (types.includes(NotificationType.Disruption))
      return NotificationType.Disruption;
    if (types.includes(NotificationType.Event)) return NotificationType.Event;
    if (types.includes(NotificationType.REPLACEMENT_SERVICE))
      return NotificationType.REPLACEMENT_SERVICE;
    if (types.includes(NotificationType.Construction))
      return NotificationType.Construction;
    return NotificationType.Info;
  }

  const screenReaderText = getScreenReaderText(
    departure,
    notifications,
    interval,
  );

  return (
    <div
      className="flex items-center gap-2 p-0 "
      aria-label={screenReaderText}
      tabIndex={0}
    >
      <TransportTypeVehicleIcon
        transportType={type}
        className={`shrink-0 pt-[calc(0.5rem*var(--row-scaling))] text-white ${checkIsTicketMachine() ? " text-[calc(5rem*var(--row-scaling))] " : "text-[calc(4.2rem*var(--row-scaling))]"}`}
      />
      <LineBadge
        lineName={lineName}
        lineBgColor={lineBgColor}
        lineFgColor={lineFgColor}
        disabled={false}
      />
      <AccessibilityLevelIcon
        accessibility={wheelchairAccessible}
        className={` w-[calc(3rem*var(--row-scaling))] shrink-0 pt-[calc(0.5rem*var(--row-scaling))] text-white ${checkIsTicketMachine() ? " ml-6 text-[calc(4rem*var(--row-scaling))]" : "ml-3 text-[calc(3.5rem*var(--row-scaling))]"}`}
      />
      <AlertIcon
        notificationType={getType()}
        className={`ml-3 ${
          isAffected
            ? `w-[calc(3rem*var(--row-scaling))] shrink-0 pt-[calc(0.5rem*var(--row-scaling))] text-[calc(3.5rem*var(--row-scaling))] ${affectedByNotification.some((notification) => notification === activeNotification?.id) ? "opacity-100" : "opacity-50"} ${checkIsTicketMachine() ? " text-[calc(4rem*var(--row-scaling))] " : "text-[calc(3.5rem*var(--row-scaling))]"}`
            : `invisible ${checkIsTicketMachine() ? " w-[calc(4rem*var(--row-scaling))]" : " w-[calc(3rem*var(--row-scaling))]"}`
        }`}
      />
    </div>
  );
}
